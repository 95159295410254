h1, h3, h5 {
  font-family: serif;
  font-weight: 100;
  margin: 0;
}

h1 {
  font-size: 3rem;
}

h3 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.2rem;
}

body {
  font-family: sans-serif;

  p {
    line-height: 1.5;
    
    a {
      color: $red;
    }
  }

  .li1 {
    margin-bottom: 20px;
  }
}




a {
  color: inherit;
  text-decoration: none;
}