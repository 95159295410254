$white: #fff;
$grey: #eee;
$dark-grey: #bbb;
$black: #000;
$dark-red: #4c0b16;
$red: #c20b21;
$blue: $dark-red;

$maxW: 1300px;

$information-tablet: "only screen and (max-width : 700px)";
$information-desktop: "only screen and (min-width : 701px)";
$information-4k: "only screen and (min-width : 1201px)";

@import "styles/typography.scss";
@import "styles/widgets.scss";

body {
  margin: 0;
  background-color: white;
}

#header {
  $h: 100px;

  background-color: $white;
  height: $h;
  padding: 0 60px;
  position: relative;
  white-space: nowrap;
  max-width: $maxW;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;

  * {
    vertical-align: top;
  }

  #branding {
    display: inline-block;
    line-height: $h;
    padding-top: 5px;
    img {
      vertical-align: middle;
      width: auto;
      height: $h - 40px;
    }
  }

  @media #{$information-tablet} {
    padding: 0 20px;

    #branding {
      img {
        max-width: 300px;
        width: calc(100vw - 150px);
        height: auto;
      }
    }

    .menu-toggle {
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 20px;
      $w: 60px;
      height: $w;
      width: $w;
      background-color: $white;
      border: 0;

      img {
        width: 50%;
      }
    }

    #menu {
      background-color: $white;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      z-index: 1;

      > ul {
        text-decoration: none;
        list-style-type: none;
        vertical-align: bottom;
        margin: 0;
        padding: 0;

        > li {
          font-size: 1rem;
          padding: 15px;
          padding-left: 20px;

          &.current-menu-item {
            color: $white;
            background-color: $dark-red;
          }

          > ul {
            display: none;
          }
        }
      }
    }
  }

  @media #{$information-desktop} {
    .menu-toggle {
      display: none;
    }

    #menu {
      padding-left: 50px;
      display: inline-block !important;

      ul {
        text-decoration: none;
        list-style-type: none;
        vertical-align: bottom;
        margin: 0;
        padding: 0;
      }

      > ul {
        height: $h;

        > li {
          z-index: 1;
          height: $h;
          display: table-cell;
          vertical-align: bottom;
          padding: 15px 20px;
          box-sizing: border-box;
          position: relative;
          background-color: $white;
          transition: background-color 0.4s;
          font-size: 1.1rem;
          letter-spacing: 1.1px;

          &:hover {
            background-color: $grey;
          }

          > a:hover {
            color: $red;
          }

          &.current-menu-item,
          &.current-menu-parent {
            color: $white;
            background-color: $dark-red;
          }

          &:hover ul {
            height: auto;
            overflow: unset;
            max-height: fit-content;
            width: auto;
            transform: scaleY(1);
          }

          ul {
            transition: transform 0.4s;
            transform: scaleY(0);
            transform-origin: top center;
            // max-height: 0%;
            overflow: hidden;
            background-color: inherit;
            position: absolute;
            width: 250px;
            top: 100%;
            left: 0px;
            z-index: 100;

            > li {
              margin: 10px 20px;
              line-height: 50px;
              $color: $black;

              &:hover {
                color: $red;
              }
            }
          }
        }
      }
    }
  }
}

#footer {
  margin-top: 25px;
  padding-top: 25px;
  padding-bottom: 40px;
  text-align: center;
  background-color: $grey;

  #menu-footer {
    list-style-type: none;
    padding: 0;
    li {
      font-size: 1.2rem;
      color: $red;
      display: inline-block;
      margin: 0 10px;
    }
  }
}

.content-row {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  padding-right: 20px;
  padding-left: 20px;
}

.big-box {
  position: relative;
  background-color: $white;
  box-shadow: 1px 1px 11px 1px rgba(0, 0, 0, 0.33);
  border-bottom: 20px solid $white;
  margin-bottom: 20px;

  &.inline {
    display: inline-block;
    width: 310px;
    margin-right: 20px;
    margin-bottom: 20px;
    box-shadow: 1px 1px 11px 1px rgba(0, 0, 0, 0.33);
    vertical-align: top;

    @media #{$information-tablet} {
      display: block;
      width: 100%;
    }
  }

  .category-box {
    position: absolute;
    background-color: $blue;
    color: $white;
    top: -10px;
    left: 10px;
    z-index: 1;
  }

  .title,
  .excerpt,
  .user {
    padding-left: 20px;
    padding-right: 20px;
  }

  .user {
    margin: 10px 0px;
  }

  .title {
    margin: 0;
    min-height: 60px;
  }

  .excerpt {
    line-height: 1.5rem;
    height: 220px;
    overflow: hidden;
    //padding-bottom: 20px;
  }

  .img-container {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    padding: 67% 0 0 0; /* 34.37% = 100 / (w / h) = 100 / (640 / 220) */

    img {
      display: block;
      width: auto !important;
      height: 100% !important;
      max-width: initial !important;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}

#nav-below {
  padding-top: 20px;
  color: $white;

  > * {
    background-color: $red;
    padding: 10px 20px;

    &:empty {
      display: none;
    }
  }

  .nav-previous {
    float: left;
  }

  .nav-next {
    float: right;
  }
}

#container {
  max-width: $maxW;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;

  img {
    max-width: 100%;
    height: auto;
  }

  .local-after-content {
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
}

.cat-entry {
  padding: 30px;
  margin-left: -30px;
  background-color: $white;
  margin-top: 20px;
}

body.single-post,
body.category,
body.search,
body.page-template-default {
  #content {
    background-color: $white;

    width: calc(100% - 300px);
    float: left;
    padding: 20px;
    padding-left: 60px;
    padding-top: 40px;
    box-sizing: border-box;
    position: relative;

    &.page {
      padding-top: 0;
    }
  }

  #sidebar {
    width: 300px;
    padding: 20px;
    box-sizing: border-box;
    float: left;

    ul {
      list-style-type: none;
      padding: 0;

      li {
        margin: 15px;
        font-size: 0.9rem;
      }
    }

    // for the ads
    ul.xoxo > li:last-child {
      margin-top: 30px;
    }

    .widget-title {
      background-color: $red;
      color: $white;
      font-family: sans-serif;
      font-weight: 100;
      font-size: 1rem;
      margin: 20px -20px 20px -10px;
      padding: 10px 10px;
      text-transform: uppercase;
    }

    .widget_search {
      margin: 0;
      padding: 0;
      white-space: nowrap;
      position: relative;

      h3,
      label {
        display: none;
      }

      input {
        display: inline-block;
        box-sizing: border-box;
        vertical-align: top;
        border: 0;
      }

      #searchform {
        position: relative;
      }

      input#s {
        height: 35px;
        width: calc(100% - 35px);
        margin-right: -4px;
        margin-left: 4px;
        padding-left: 10px;
        font-size: 15px;
        border: 1px solid $dark-grey;
      }

      input#searchsubmit {
        height: 35px;
        font-size: 1px;
        width: 35px;
        max-width: 35px;
        content: "";
        background-color: $red;
        cursor: pointer;
      }

      &:after {
        content: "";
        position: absolute;
        right: -4px;
        top: 0;
        width: 35px;
        height: 35px;
        background-image: url("data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTguMS4xLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDI1MC4zMTMgMjUwLjMxMyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjUwLjMxMyAyNTAuMzEzOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjY0cHgiIGhlaWdodD0iNjRweCI+CjxnIGlkPSJTZWFyY2giPgoJPHBhdGggc3R5bGU9ImZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkOyIgZD0iTTI0NC4xODYsMjE0LjYwNGwtNTQuMzc5LTU0LjM3OGMtMC4yODktMC4yODktMC42MjgtMC40OTEtMC45My0wLjc2ICAgYzEwLjctMTYuMjMxLDE2Ljk0NS0zNS42NiwxNi45NDUtNTYuNTU0QzIwNS44MjIsNDYuMDc1LDE1OS43NDcsMCwxMDIuOTExLDBTMCw0Ni4wNzUsMCwxMDIuOTExICAgYzAsNTYuODM1LDQ2LjA3NCwxMDIuOTExLDEwMi45MSwxMDIuOTExYzIwLjg5NSwwLDQwLjMyMy02LjI0NSw1Ni41NTQtMTYuOTQ1YzAuMjY5LDAuMzAxLDAuNDcsMC42NCwwLjc1OSwwLjkyOWw1NC4zOCw1NC4zOCAgIGM4LjE2OSw4LjE2OCwyMS40MTMsOC4xNjgsMjkuNTgzLDBDMjUyLjM1NCwyMzYuMDE3LDI1Mi4zNTQsMjIyLjc3MywyNDQuMTg2LDIxNC42MDR6IE0xMDIuOTExLDE3MC4xNDYgICBjLTM3LjEzNCwwLTY3LjIzNi0zMC4xMDItNjcuMjM2LTY3LjIzNWMwLTM3LjEzNCwzMC4xMDMtNjcuMjM2LDY3LjIzNi02Ny4yMzZjMzcuMTMyLDAsNjcuMjM1LDMwLjEwMyw2Ny4yMzUsNjcuMjM2ICAgQzE3MC4xNDYsMTQwLjA0NCwxNDAuMDQzLDE3MC4xNDYsMTAyLjkxMSwxNzAuMTQ2eiIgZmlsbD0iI0ZGRkZGRiIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=");
        background-size: 20px 20px;
        background-repeat: no-repeat;
        background-position: 50%;
        z-index: 10;
        pointer-events: none;
      }
    }
  }

  @media #{$information-tablet} {
    #content,
    #sidebar {
      width: 100%;
    }

    #content {
      padding-left: 20px;
    }
  }

  #footer {
    padding-top: 20px;
    padding-bottom: 20px;
    clear: both;
  }
}

#content.no-title {
  overflow: hidden;
  padding: 0;
  background-color: $white;
}

.tmpl-sub {
  .next {
    font-weight: 100;
    margin-bottom: 10px;
  }

  background-color: $dark-red;
  padding: 20px;
  width: 20%;
  min-width: 300px;
}

.banner-box {
  color: $white;
  position: relative;
  min-height: 300px;
  box-shadow: 1px 1px 11px 1px rgba(0, 0, 0, 0.33);

  .title {
    margin: 0;
  }

  .banner {
    height: 100%;
    img {
      max-width: 10000px !important;
      width: 100% !important;
      height: auto !important;
      margin-bottom: -5px;
    }
  }

  .main,
  .sub {
    box-sizing: border-box;
    //border: 1px solid;
  }

  $w: 240px;

  .main-sub-container {
    display: flex;
    position: absolute;
    width: 100%;
    bottom: -1px;
    align-items: stretch;
    background-color: $red;

    @media #{$information-desktop} {
      background-position: 0 100%;
      background-size: 100% auto;
      background-color: $red;
      background-blend-mode: multiply;
    }

    @media #{$information-tablet} {
      position: relative;
      background-image: initial !important;

      flex-direction: column;

      .main,
      .sub {
        width: 100% !important;
      }
    }

    .main {
      padding-left: 40px;

      @media #{$information-4k} {
        padding-left: 120px;
      }

      @media #{$information-tablet} {
        padding-left: 15px;
        padding-right: 5px;
      }

      flex-grow: 1;
      width: calc(80%);
      padding-bottom: 20px;

      .user {
        margin-top: -15px;
      }

      .category-box {
        position: relative;
        top: -20px;
        color: $black;
      }
    }

    .sub {
      @extend .tmpl-sub;
    }
  }
}

.banner-single {
  position: relative;
  margin-bottom: 0px;

  .img-container {
    //max-height: 500px;
    overflow: hidden;
    img {
      width: calc(100% - 300px);
      margin-bottom: -4px;
      height: auto;
    }
  }

  .category-box {
    position: absolute;
    bottom: -20px;
    left: 60px;
    z-index: 100;
  }

  .sub {
    @extend .tmpl-sub;
    position: absolute;
    background-color: $dark-red;
    color: $white;
    top: 0;
    right: 0;
    height: calc(100% - 0px);
    width: 300px;
    box-sizing: border-box;
    overflow: hidden;
    border-bottom: 20px solid $dark-red;
  }

  @media #{$information-tablet} {
    .sub {
      display: none;
    }

    .category-box {
      left: 20px;
    }

    .img-container img {
      height: 150px;
      width: auto;
    }
  }
}

.category-box {
  background-color: white;
  font-weight: normal;
  letter-spacing: 2px;
  display: inline-block;
  padding: 10px 20px;
  text-transform: uppercase;

  &.dark {
    background-color: $dark-red;
    color: $white;
  }
}

.user {
  line-height: 40px;
  font-size: 0.8em;
  padding-top: 5px;

  > * {
    vertical-align: middle;
  }

  img {
    $imgw: 30px;
    position: relative;
    top: -3px;
    width: $imgw;
    height: $imgw;
    border-radius: 50%;
    margin-right: 5px;
    background-color: lightgrey;
  }

  .name {
    font-weight: normal;
    margin-right: 10px;
  }

  .date {
    color: $dark-grey;
    font-weight: 100;
  }
}

.widget_categories > ul > li {
  font-weight: bold;
  &:before {
    content: "> ";
  }
}

.widget_categories > ul > li > ul {
  font-weight: normal;
}

.so-widget-hello-world-widget {
  padding: 0px;

  h1 {
    padding-bottom: 30px;
    font-size: 2rem;
  }
}

.entry-title {
  margin-top: 30px;
  margin-bottom: 20px;
}

.content-info,
.sidebar-info {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.nav-info {
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  height: auto;

  display: none;
  @media #{$information-4k} {
    display: block;
  }

  img {
    width: auto;
    height: 100px;
    z-index: -1;
  }
}

#copyright {
  font-size: 8pt;
  color: #1a1a1a;
}

.aligncenter {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

.schaukasten {
  /* border:  5px solid grey; */
  background: #4c0b16;
  color: white;
  padding: 30px;
  line-height: 1.5rem;
  font-style: italic;
  margin-bottom: 20px;
  box-shadow: 5px 5px 12px 0px rgba(76, 11, 22, 0.44);

  h3 {
    margin-bottom: 20px;
  }
}

.social-media a {
  background-color: #c6102c;
  font-size: 20pt;
  color: white;
  padding: 5px;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 40px;
  margin: 0px 5px;
  transition: transform 0.4s;
}

.social-media a:hover {
  transform: scale(1.1);
}

.menu-item.red {
  color: $red;
}

span.premium {
  color: $red;
  margin-left: -5px;
  margin-right: 5px;
}

.big-box.premium {
  background: #eee;
  border-color: #eee;
}

// IE FIX

@media (max-width: 700px) {
  #menu-main > .menu-item > a {
    font-weight: bold;
  }

  ul.sub-menu {
    display: block !important;
    list-style-type: none;
    padding: 0;
    > li {
      padding-top: 1rem;
    }
  }
}
